import { get, includes, set, toLower, map, trim } from "lodash";
import { v1 as uuidv1 } from "uuid";
import { UAParser } from "ua-parser-js";
import Cookies from "js-cookie";
import md5 from "md5";
import "core-js/stable";

declare global {
  interface Window {
    // Digital Analytics
    digitalData: any;
  }
}

export enum DATA_INITIALIZATION_STATUS {
  uninitialized = "uninitialized",
  initialized = "initialized",
  failed = "failed",
}

export class Config {
  // 'lnk' is added as a allow list value, need to make it a block list
  // fix this for now just for lnk, lnk2, psrc & pexp and do the block list later
  static readonly IS_PRODUCTION_ENVIRONMENT: boolean =
    process.env.NODE_ENV === "production";

  static ID_SESSION_TELEAF: string;

  static ID_UPID: string;

  static ID_SESSION_APP: string;

  static IS_IBMER = -1;

  static readonly SIZES_LOG_PAYLOAD_ATTRIBUTE_Q_MAX = 255;

  static readonly NAME_SESSION_ID_APP = "EVENTS_CAPTURE_SESSION_ID_APP";

  static ID_ADOPTER_CURRENT = "n/a";

  static readonly NAME_COOKIE_ID_TEALEAF = "TLTSID";

  static readonly NAME_COOKIE_ID_UPID = "BMAID";

  static readonly SESSION_HASHED_QUERIES: string = "mh_hashedQueries";

  static readonly SESSION_HASHED_NOHITS_QUERIES: string =
    "mh_hashedNoHitsQueries";

  static readonly ARRAY_INFO_ALREADY_LOGGED: any = [];

  static readonly INFO_USER_AGENT = (() => {
    const parser = new UAParser(),
      userAgent = get(window, "navigator.userAgent");

    parser.setUA(userAgent);

    return parser.getResult();
  })();

  static getCookie(cookieName: string): string {
    return Cookies.get(cookieName);
  }

  static setSessionStorageItem(key: string, value: string) {
    window.sessionStorage.setItem(key, value);
  }

  static getSessionStorageItem(key: string): string {
    return window.sessionStorage.getItem(key) || "[]";
  }

  static getValueArrayOfSessionStorageItem(key: string): Array<string> {
    try {
      const sessionStorageValueArray = JSON.parse(
        Config.getSessionStorageItem(key)
      );

      return sessionStorageValueArray;
    } catch (err) {
      console.error(err);
    }

    return [];
  }

  static normalizeAndHashTerm(q: string, cc: string, lc: string): string {
    try {
      const normalizedValue = `${trim(toLower(q))}${trim(toLower(cc))}${trim(
          toLower(lc)
        )}`,
        normalziedHashedValue = md5(normalizedValue);

      return normalziedHashedValue;
    } catch (err) {
      console.error(err);
    }

    return "";
  }

  static addValueToOfSessionStorageItemArray(
    key: string,
    q: string,
    cc: string,
    lc: string
  ) {
    const normalizedAndHashedTerm = Config.normalizeAndHashTerm(q, cc, lc),
      arrayOfSessionStorageItems =
        Config.getValueArrayOfSessionStorageItem(key);

    if (!includes(arrayOfSessionStorageItems, normalizedAndHashedTerm)) {
      arrayOfSessionStorageItems.push(normalizedAndHashedTerm);
      Config.setSessionStorageItem(
        key,
        JSON.stringify(arrayOfSessionStorageItems)
      );
    }
  }

  static hasQueryBeenMadeInSession(
    key: string,
    q: string,
    cc: string,
    lc: string
  ): boolean {
    const normalizedAndHashedTerm = Config.normalizeAndHashTerm(q, cc, lc),
      arrayOfSessionStorageItems =
        Config.getValueArrayOfSessionStorageItem(key),
      normalizedAndHashedTermExistsInArrayOfSessionStorageItems = includes(
        arrayOfSessionStorageItems,
        normalizedAndHashedTerm
      );

    return normalizedAndHashedTermExistsInArrayOfSessionStorageItems;
  }

  static hasNoResultForQueryBeenMadeInSession(
    key: string,
    q: string,
    cc: string,
    lc: string
  ): boolean {
    const normalizedAndHashedTerm = Config.normalizeAndHashTerm(q, cc, lc),
      arrayOfSessionStorageItems =
        Config.getValueArrayOfSessionStorageItem(key),
      normalizedAndHashedTermExistsInArrayOfSessionStorageItems = includes(
        arrayOfSessionStorageItems,
        normalizedAndHashedTerm
      );

    return normalizedAndHashedTermExistsInArrayOfSessionStorageItems;
  }

  static readonly LOG_INFO_USER_AGENT = {
    browser: get(Config.INFO_USER_AGENT, "browser.name"),
    version: get(Config.INFO_USER_AGENT, "browser.version"),
    os: get(Config.INFO_USER_AGENT, "os.name"),
    device: get(Config.INFO_USER_AGENT, "device.type"),
  };

  static readonly LOG_CLIENT_SIDE: any = Config._initialize();

  static _initialize() {
    const R7Insight:any = get(window, "R7Insight");

    let ct = 0;
    
    let nIntervId = setInterval(() => {
        if (ct < 10) {
            if (R7Insight) {
                // It is not secret as it is deployed on each load of the bundle
                // Test
                // **************************************************************************************************
                // IMPORTANT: The token below is a "write only" token to log events from the end users' web browsers.
                // **************************************************************************************************
                // R7Insight.init({token: '96f0f037-9b77-4a8e-998c-1d834272e67b', region: 'eu'});

                // Production
                // **************************************************************************************************
                // IMPORTANT: The token below is a "write only" token to log events from the end users' web browsers.
                // **************************************************************************************************
                R7Insight.init({token: '78869ac0-3765-48f3-8c32-290ef0f5dc89', region: 'eu'});
                
                clearInterval(nIntervId)
            }
        } else { console.info('hit out max and we quit');
            clearInterval(nIntervId);
        }
        ct++;
    }, 1000);

    return R7Insight;
  }

  static setAdopter(adopter: any) {
    console.log(
      `Search insight adopter values set to ${JSON.stringify(adopter)}`
    );
    Config.ID_ADOPTER_CURRENT = adopter;
  }

  static getAdopter(): string {
    return Config.ID_ADOPTER_CURRENT;
  }

  static clientSideLogInfoOncePerPageLoad(logInfoObject: object) {
    const stringToHash = JSON.stringify(logInfoObject),
      md5HashedLogInfo = md5(stringToHash),
      isLogInfoAlreadyLogged = includes(
        Config.ARRAY_INFO_ALREADY_LOGGED,
        md5HashedLogInfo
      );

    if (!isLogInfoAlreadyLogged) {
      Config.clientSideLogInfo(logInfoObject);
      Config.ARRAY_INFO_ALREADY_LOGGED.push(md5HashedLogInfo);
    } else {
      console.log(
        `Skipping logging of event that should only trigger once per page load:\n${JSON.stringify(
          logInfoObject
        )}`
      );
    }
  }

  static clientSideLogInfo(logInfoObject: object) {
    if (
      !Config.ID_SESSION_APP &&
      Config.getSessionStorageItem(Config.NAME_SESSION_ID_APP) === "[]"
    ) {
      Config.setSessionStorageItem(Config.NAME_SESSION_ID_APP, uuidv1());
    }

    const upid = Config.getCookie(Config.NAME_COOKIE_ID_UPID) || "n/a",
      teleafID = Config.getCookie(Config.NAME_COOKIE_ID_TEALEAF) || "n/a",
      sessionAppID = Config.getSessionStorageItem(Config.NAME_SESSION_ID_APP),
      industry = get(
        Config.getDigitalDataGlobal(),
        "user.company.tp.industry",
        "n/a"
      ),
      companyName = get(
        Config.getDigitalDataGlobal(),
        "user.userInfo.company_name",
        "n/a"
      ),
      isIBMer = get(Config.getDigitalDataGlobal(), "user.segment.isIBMer", -1),
      locationHost = get(window, "location.host", "n/a");

    set(logInfoObject, "timestamp", Date.now());
    set(logInfoObject, "uuid", uuidv1());
    set(logInfoObject, "tealeafID", teleafID);
    set(logInfoObject, "userAgent", Config.LOG_INFO_USER_AGENT);
    set(logInfoObject, "upid", upid);
    set(logInfoObject, "isIBMer", isIBMer);
    set(logInfoObject, "locationHost", locationHost);
    set(logInfoObject, "companyName", companyName);
    set(logInfoObject, "industry", industry);
    set(logInfoObject, "appID", get(Config.getAdopter(), "appId"));
    set(logInfoObject, "appSessionID", sessionAppID);
    set(logInfoObject, "referrerURL", get(document, "referrer", "n/a"));
    set(logInfoObject, "ipAddress", "n/a");

    const trimmedLongInfoObject = Config.trimExcessiveAttributes(logInfoObject);

    const logMessage = JSON.stringify(trimmedLongInfoObject).replace(
      /'/g,
      "&#x27;"
    );

    if (Config.LOG_CLIENT_SIDE) {
      Config.LOG_CLIENT_SIDE.info(logMessage);
    } else {
      console.log(logMessage);
    }
  }

  static trimExcessiveAttributes(logInforObject: any): any {
    const logInfoDeepClone = JSON.parse(JSON.stringify(logInforObject)),
      hasStateBookMark = get(logInfoDeepClone, "state.bookmark"),
      q = get(logInfoDeepClone, "state.q"),
      results = get(logInfoDeepClone, "results");

    if (hasStateBookMark) {
      delete logInfoDeepClone.state.bookmark;
    }

    if (q && q.length > Config.SIZES_LOG_PAYLOAD_ATTRIBUTE_Q_MAX) {
      set(
        logInfoDeepClone,
        "state.q",
        q.substring(0, Config.SIZES_LOG_PAYLOAD_ATTRIBUTE_Q_MAX)
      );
    }

    set(
      logInfoDeepClone,
      "results",
      map(results, (result) => {
        return {
          url: get(result, "url", "n/a"),
          sm: get(result, "sm", false),
        };
      })
    );

    return logInfoDeepClone;
  }

  static getTealeafObject(): any {
    return get(window, "TLT");
  }

  static isTealeafInitialized(): boolean {
    const tealeafGlobal = Config.getTealeafObject();

    return tealeafGlobal && tealeafGlobal.isInitialized
      ? tealeafGlobal.isInitialized()
      : true;
  }

  //   static getDDOData (props: any): any {
  //     const isProductsPage = get(props, 'isProductsPage'),
  //       cc = get(props, 'cc'),
  //       CC = toUpper(cc),
  //       lang = get(props, 'lang'),
  //       overrides = get(props, 'overrides', {}),
  //       page = get(props, 'page', '1'),
  //       searchTerm = get(props, 'searchTerm', ''),
  //       staticValues = isProductsPage ? Config.ANALYTICS_DDO_PRODUCTS : Config.ANALYTICS_DDO_SEARCH,
  //       siteID = get(staticValues, 'siteID', ''),
  //       publishDate = get(staticValues, 'publishDate'),
  //       expiryDate = this.getExpiryDate(publishDate),
  //       computedValues = {
  //         CC,
  //         effectiveDate: publishDate,
  //         expiryDate,
  //         lc: lang,
  //         lcCC: `${lang}-${CC}`,
  //         pageID: `${siteID}:${searchTerm}:${page}`,
  //         publishDate,
  //         searchTerm
  //       },
  //       data = extend(staticValues, computedValues, overrides);

  //       return data;
  //   }

  static getDigitalDataGlobal(): any {
    return window.digitalData;
  }
}
