import { Config } from "./Config";
import { State } from "./State";
import { Result } from "./Result";
import { Metrics } from "./Metrics";
import { get } from "lodash";
import "core-js/stable";

class IBMSearchInsightsData {
  constructor() {
    console.log("Library constructor loaded");
  }

  static readonly ADOPTERS = {
    DOCS: {
      appId: "docs",
      prefix: "docs",
    },
    LICENSING: {
      appId: "licensing",
      prefix: "licensing",
    },
    LICENSING_PRODUCTS: {
      appId: "licensing_products",
      prefix: "licensing_products",
    },
    CAREERS: {
      appId: "careers",
      prefix: "careers",
    },
    CASE_STUDIES: {
      appId: "casestudies",
      prefix: "casestudies",
    },
    PRODUCTS: {
      appId: "products",
      prefix: "products",
    },
    SUPPORT: {
      appId: "support",
      prefix: "support",
    },
    TRAINIG: {
      appId: "training",
      prefix: "training",
    },
    PARTNER_PLUS_DIRECTORY: {
      appId: "partner_plus_directory",
      prefix: "partner_plus_directory",
    },
    GLOBAL_SOLUTIONS_DIRECTORY: {
      appId: "global_solutions_directory",
      prefix: "global_solutions_directory",
    },
    PARTNER_SHOWCASE: {
      appId: "partner_showcase",
      prefix: "partner_showcase",
    },
    PARTNER_PLUS: {
      appId: "partner_plus",
      prefix: "partner_plus",
    },
    SDMA: {
      appId: "sdma",
      prefix: "sdma",
    },
    DEVELOPER: {
      appId: "developer",
      prefix: "developer",
    },
    FIX_CENTRAL: {
      appId: "fix_central",
      prefix: "fix_central",
    },
    RESEARCH: {
      appId: "research",
      prefix: "research",
    },
    SALES_KNOWLEDGE_MANAGEMENT: {
      appId: "sales_knowledge_management",
      prefix: "sales_knowledge_management",
    },
    SALES_KNOWLEDGE_MANAGEMENT_SUPPORT: {
      appId: "sales_knowledge_management_support",
      prefix: "sales_knowledge_management_support",
    },
    SUPPORT_DRUPAL: {
      appId: "support_drupal",
      prefix: "support_drupal",
    },
    SUPPORT_PSIRT_SECURITY_BULLETIN: {
      appId: "support_psirt_security_bulletin",
      prefix: "support_psirt_security_bulletin",
    },
    SUPPORT_PRODUCT_LIFECYCLE: {
      appId: "support_lifecycle",
      prefix: "support_lifecycle",
    },
  };

  static readonly TAB_TYPES = {
    ALL: "all",
    DOCUMENTATION: "documentation",
    DEVELOPER: "developer",
    SUPPORT: "support",
    TECH_GUIDE_REDBOOKS: "tech-guides-redbooks",
    LEARNING: "learning",
    API_HUB: "ibm-api-hub",
  };

  static setAdopter(adopter: any) {
    console.log(
      `Search insight adopter values set to ${JSON.stringify(adopter)}`
    );
    Config.setAdopter(adopter);
  }

  static getAdopter(): any {
    return Config.getAdopter();
  }

  static logPageLoad() {
    Config.clientSideLogInfoOncePerPageLoad({
      action: "Page loaded",
    });
  }

  static logPageUnload() {
    Config.clientSideLogInfoOncePerPageLoad({
      action: "Page unloaded",
    });
  }

  static logNoResults(state: State) {
    const q = get(state, "q"),
      cc = get(state, "cc"),
      lc = get(state, "lc");

    if (
      !Config.hasQueryBeenMadeInSession(
        Config.SESSION_HASHED_NOHITS_QUERIES,
        q,
        cc,
        lc
      )
    ) {
      Metrics.logNoResults(state);
      Config.addValueToOfSessionStorageItemArray(
        Config.SESSION_HASHED_NOHITS_QUERIES,
        q,
        cc,
        lc
      );
    } else {
      console.log(`Skipping logging of non unique query 'no results' ${q}`);
    }
  }

  static async logUniqueQuery(state: State, results: [Result]) {
    const q = get(state, "q"),
      cc = get(state, "cc"),
      lc = get(state, "lc");

    if (
      !Config.hasQueryBeenMadeInSession(
        Config.SESSION_HASHED_QUERIES,
        q,
        cc,
        lc
      )
    ) {
      await Metrics.logUniqueSearch(state, results);
      Config.addValueToOfSessionStorageItemArray(
        Config.SESSION_HASHED_QUERIES,
        q,
        cc,
        lc
      );
    } else {
      console.log(`Skipping logging of non unique query ${q}`);
    }
  }

  static logClick(e: any, state: State, results: [Result]) {
    Metrics.logClick(e, results, state);
  }
}

// Runtime setup
window["IBMSearchInsightsData"] = IBMSearchInsightsData;

export default IBMSearchInsightsData;
