import { get, keys, defaultsDeep, isArray, set, find, filter} from "lodash";
import { Config } from "./Config";
import { State } from "./State";
import { Result } from "./Result";
import "core-js/stable";
import { error } from "console";
export enum SourceClick {
  RESULTS = "Results",
  UNKNOWN = "Unknown",
}

export enum ClickedButton {
  LEFT = "Left",
  RIGHT = "Right",
  MIDDLE = "Middle",
  UNKNOWN = "Unknown",
}

export class Metrics {
  private static FUNCTION_LOOKUP_LOG_CLICK = {
    [SourceClick.RESULTS]: Metrics.logClickResults,
  };

  private static FIELD_DEFAULTS_CLICK_INFO = {
    text: "",
    id: "",
    href: "",
    refinement: "",
    resultType: "",
    position: 0,
  };

  private static FIELD_DEFAULTS_STATE_INFO = {
    q: "",
    lang: "",
    cc: "",
    tabType: ["$all"],
    facetTabs: "",
    origin: "",
  };

  private static FIELD_DEFAULTS_LOG = {
    sourceClick: "",
    action: "",
    appID: "",
    appSessionID: "",
    clickInfo: Metrics.FIELD_DEFAULTS_CLICK_INFO,
    isIBMer: 0,
    // @ts-ignore
    results: [],
    state: Metrics.FIELD_DEFAULTS_STATE_INFO,
    tealeafID: "",
    timestamp: 0,
    type: "",
    userAgent: {},
    uuid: "",
  };

  private static REGEX_MATCH_ELEMENT_CLICKED_ID = {
    [SourceClick.RESULTS]: /^search-result-.*$/,
  };

  private static DEBOUNCE_TIME = 2000;

  private static clicklist: any = [];

  public static logUniqueSearch(state: State, results: [Result]): any {
    return Metrics.clientSideLogInfo({
      action: "unique query was made",
      type: "user behavior tracking",
      state,
      results,
    });
  }

  public static logNoResults(state: State): any {
    return Metrics.clientSideLogInfo({
      action: '"no hits" search results returned',
      type: "user behavior tracking",
      state,
    });
  }

  protected static getSourceClick(e: any): SourceClick {
    const eventTarget = e.target,
      anchor = eventTarget.closest("a");

    let id,
      sourceClick = SourceClick.UNKNOWN;

    if (anchor) {
      id = get(anchor, "id");
    }

    if (!id) {
      const article = eventTarget.closest("article");

      id = get(article, "id");
    }

    if (!id) {
      const parentDivWithID = eventTarget.closest("div[id]");

      id = get(parentDivWithID, "id");
    }

    if (!id) {
      const eventTargetVDOM = get(e, "path[0]"),
        parentWithID =
          eventTargetVDOM.closest("a[id]") ||
          eventTargetVDOM.closest("div[id]");

      id = get(parentWithID, "id");
    }

    if (!id) {
      id = "n/a";
    } else {
      // console.log(`Target ID is "${id}"`);

      const regexMatchElementKeys = keys(
        Metrics.REGEX_MATCH_ELEMENT_CLICKED_ID
      );

      for (const regexMatchElementKey of regexMatchElementKeys) {
        const regex = get(
            Metrics.REGEX_MATCH_ELEMENT_CLICKED_ID,
            regexMatchElementKey
          ),
          match = id.match(regex);

        if (match) {
          sourceClick = regexMatchElementKey as SourceClick;
        }
      }
    }

    return sourceClick;
  }

  protected static getTargetOrPath(e: any): any {
    const eventTarget = e.target,
      eventTargetVDOM = get(e, "path[0]"),
      targetOrPath = eventTargetVDOM || eventTarget;

    return targetOrPath;
  }

  protected static getButtonClicked(e: any) {
    // events.button == 1 (left mouse button)
    // events.button == 2 (right mouse button)
    // events.button == 4 (mid mouse button)
    let buttonClicked;

    // console.log(`Clicked on a button`);
    const eventButton = e.buttons || e.which;

    switch (eventButton) {
      case 1:
        buttonClicked = ClickedButton.LEFT;
        break;
      case 2:
        buttonClicked = ClickedButton.RIGHT;
        break;
      case 4:
        buttonClicked = ClickedButton.MIDDLE;
        break;
      default:
        buttonClicked = ClickedButton.UNKNOWN;
        break;
    }

    return buttonClicked;
  }

  private static shouldLogClickEvent (clickInfo: any) {
    const href = get(clickInfo, 'href');
    Metrics.clicklist = filter(Metrics.clicklist, i => (new Date().getTime() - i.ts) < Metrics.DEBOUNCE_TIME);

    const found = find(Metrics.clicklist, { href }) || false;
    if (!found) {
      Metrics.clicklist.push({
         href,
         ts: new Date().getTime(),
      });

       return true;
    }

    return false;
  }

  public static logClick(e: any, results: Result[], state: State): any {

    try {
      return Metrics.logClickAsync(e, results, state);
    } catch (err) {
      const clickInfo = {
        action: "trying to log a click",
        text: `could not handle logging the clicked element`,
      };
      return Metrics.clientSideLogInfo({
        action: "Error was thrown",
        error: clickInfo,
      });
    }
  }

  public static async logClickAsync(
    e: any,
    results: Result[],
    state: State
  ): Promise<any> {
    try {
      const sourceClick = Metrics.getSourceClick(e),
        buttonClicked = Metrics.getButtonClicked(e),
        logClickFunction = get(
          Metrics.FUNCTION_LOOKUP_LOG_CLICK,
          sourceClick,
          Metrics.logClickUnknown
        ),
        clickInfo: any = logClickFunction(e),
        logPayload = {
          action: "clicked on result",
          type: "user behavior tracking",
          sourceClick,
          clickInfo,
          buttonClicked,
          state,
          results,
        },
        shouldLog = sourceClick === 'Results' ? Metrics.shouldLogClickEvent(clickInfo) : true;

      if (!shouldLog) throw new Error('clicked to many times');
        
      return Metrics.clientSideLogInfo(logPayload);
    } catch (err) {
      return {};
    }
    return {};
  }

  private static clientSideLogInfo(logPayload: any): any {
    const logPayloadDefaulted = defaultsDeep(
        logPayload,
        Metrics.FIELD_DEFAULTS_LOG
      ),
      logPayloadDefaultedOverrriden =
        Metrics.applyValueOverridesToLogPayload(logPayloadDefaulted);

    return Config.clientSideLogInfo(logPayloadDefaultedOverrriden);
  }

  public static applyValueOverridesToLogPayload(logPayload: any): any {
    const faceTabsValue = get(logPayload, "state.facetTabs"),
      tabTypeValue = get(logPayload, "state.facetTabs");

    if (isArray(faceTabsValue)) {
      set(logPayload, "state.facetTabs", get(faceTabsValue, "0", ""));
    }

    if (!isArray(tabTypeValue)) {
      set(logPayload, "state.tabType", ['$all']);
    }

    return logPayload;
  }

  public static logClickUnknown(e: any): any {
    const currentElement = Metrics.getTargetOrPath(e).closest("a"),
      targetID = get(currentElement, "id", ""),
      href = get(currentElement, "href", ""),
      text = "",
      position = 0,
      clickInfo = {
        text,
        id: targetID,
        href,
        refinement: get(this, "searchkit.state.tabType[0]", "$all"),
        position,
      };

    return clickInfo;
  }

  public static logClickResults(e: any): any { 
    const currentElement = Metrics.getTargetOrPath(e).closest("a"),
      targetID = currentElement.id,
      href = currentElement.href,
      text = currentElement.innerText,
      position = currentElement.getAttribute("data-position"),
      resultType = currentElement.getAttribute("data-resulttype"),
      clickInfo = {
        text,
        id: targetID,
        href,
        refinement: get(this, "searchkit.state.tabType[0]", "$all"),
        position,
        resultType,
      };

    return clickInfo;
  }
}
